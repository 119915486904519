<template>
<div style="margin-top: 100px">
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-5 col-lg-6">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchClients">Buscar</button>
                            </div>
                        </div>
                
                  


                        <div class="col-12 col-md-6 col-lg-3 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'ClientAccessNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="fas fa-street-view mr-2"></i>Nova permissão</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">E-mail</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Apagar</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="can in cans" :key="can.id" > 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row" >
                                      
                                        <div class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-10 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="card-body-list-title">{{ can.client.first_name }} {{ can.client.last_name }}</h6>
                                                    </div>
                                                  <div class="col-12 col-md-10 col-lg-5 align-self-center pad-content-list">
                                                        <h6 class="card-body-list-title">{{ can.client.email }} </h6>
                                                    </div>
                                          
                                                 <div class="col-2 col-md-2 col-lg-3 align-self-center pad-content-list">
                                                        <i class="far fa-trash-alt" style="color:red" @click="removerPermissao(can.client_id)"></i>
                                                    </div>
                                               </div>
                                            </div>
                                        </div>
                                        <div  class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center p-4" v-if="cans.length == 0">
                        Nenhuma permissão encontrada.
                    </div>

                   
                    </div>


                    
                </div>
                <div class="col-12">
                    <BasePagination
                        v-if="clients.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
</div>
</template>
<script>
import ClientCanService from "@/services/resources/ClientCanService";


const service = ClientCanService.build();

export default {
    data(){
        return{
            disabledButtonSyncronized: false,
            clients: [],
            total_clients: 0,
            total_uau: 0,
            cans: [],
            uau_clients_not_imported: [],
            integrations: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'clientes',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ]
        }
    },
    methods:{
        sincronizedUAU() {

            this.disabledButtonSyncronized = true;
                this.$bvToast.toast('Processo de importação de dados em andamento, por favor aguarde.', {
                    title: 'Atenção',
                    autoHideDelay: 10000,
                    type: 'success'
                    });

            serviceSyncUsersUau.search('').then(() => {
                this.disabledButtonSyncronized = false;
            })
        },  
         toPage(page) {
            this.pagination.currentPage = page;
            this.fetchClients();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchClients();
        },
        removerPermissao(id) {
            var  informacao = {
                id: id
            }
             service
                .search(informacao)
                .then(resp => {
                    this.fetchClients()
                       this.$bvToast.toast('Permissão apagada', {
                    title: 'Permissão apagada',
                    autoHideDelay: 10000,
                    type: 'success'
                    });
                })
                .catch(err => {
                console.log(err)
                })
        },
        listUAUClientsNotImported() {
            serviceImportNotImportedClient.search().then(function(re) {
                this.uau_clients_not_imported = re
            }.bind(this))
        },
        importClient(i) {
            let information = {
                client_uau:  this.uau_clients_not_imported[i]["Cod_pes"]
            }
            serviceImportNotImportedClient.create(information ).then(function(re) {
                console.log(re);
            }.bind(this))
           
        },
        fetchClients(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    this.cans = resp
                    this.pagination.totalPages = resp[0].last_page;
                    this.pagination.itens = resp[0].total;
                })
                .catch(err => {
                console.log(err)
                })
        },
    },
    mounted(){
        this.fetchClients();
        this.integrations =  this.$store.getters.getProfile.company.integrations
        console.log(this.$store.getters.getProfile);
    }
}
</script>